const ADMIN_HOSTNAME = 'admin.elendi.ai'
const ADMIN_STAGING_HOSTNAME = 'admin-staging.elendi.ai'

export const getAdminHostname = () => {
  const env = process.env['NEXT_PUBLIC_VERCEL_ENV']
  const overriddenHostname = process.env['ADMIN_HOSTNAME']
  if (overriddenHostname) return overriddenHostname
  if (env === 'production') return ADMIN_HOSTNAME
  if (env === 'development') return ADMIN_STAGING_HOSTNAME
  return ADMIN_HOSTNAME
}
