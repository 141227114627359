import useTranslation from 'next-translate/useTranslation'

export const ErrorMessage = ({ code }: { code?: string }) => {
  const { t } = useTranslation()

  return (
    <div>
      <p>{t('common:error.500.title')}</p>
      {code && <p className='text-white dark:text-slate-900'>{code}</p>}
    </div>
  )
}
