import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { Fragment } from 'react'
import {
  RiBuilding4Fill,
  RiLoginCircleLine,
  RiLogoutCircleLine,
  RiTeamFill,
  RiUser3Fill,
} from 'react-icons/ri'

import { ROUTE } from '@elendi/util-endpoints'
import { useIsMobile } from '@elendi/util-hooks'
import { EDUCATION_PAGES, PageType, TeamMemberType } from '@elendi/util-types'

import { CircularLoader } from '@elendi/ui-loaders'
import { H3 } from '@elendi/ui-typography'

import {
  defaultSignIn,
  defaultSignOut,
  getName,
  getRole,
  isAdmin,
  isAtLeastUser,
} from '@elendi/feature-authentication/utils'
import { useUserInfos } from '@elendi/feature-user-profile/hooks'

import { AdministrationPanel } from './AdministrationPanel'
import { LanguageChanger } from './LanguageChanger'
import { ThemeChanger } from './ThemeChanger'
import { UserMenuButton } from './UserMenuButton'
import { UserMenuItem } from './UserMenuItem'

export const UserMenu = ({ pageType }: { pageType: PageType }) => {
  const { t } = useTranslation()
  const { userInfos, isLoading, isError } = useUserInfos()
  const { data: session } = useSession()
  const isMobile = useIsMobile(640)

  if (isError || !session) return null

  const sessionUserName = getName(session)
  const sessionUserRole = getRole(session)
  const sessionUserIsAdmin = isAdmin(session)

  const { isAdmin: isAdminBool } = userInfos ?? {}

  const isEnterpriseUser = TeamMemberType.ENTERPRISE === userInfos?.type
  const isManager = isEnterpriseUser ? userInfos.is_manager : false
  const isHr = isEnterpriseUser ? userInfos.isHr : false
  const isSuper = isEnterpriseUser ? userInfos.isSuperManager : false
  const isAtleastManager = isManager || isHr || isSuper || isAdminBool

  const pageIsEducation = EDUCATION_PAGES.includes(pageType)

  const showMyProfileLink = isAtLeastUser(sessionUserRole)
  const showMyTeamInfoLink = isAtleastManager && !pageIsEducation
  const showProfileDirectoryLink =
    (isHr || isAdminBool || !!userInfos?.canAccessPeopleDirectory) && !pageIsEducation

  const showAdministrationPanel = sessionUserIsAdmin

  return (
    <Menu as='div' className='flex sm:relative'>
      {({ open }) => (
        <>
          <UserMenuButton open={open} />

          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className={classNames(
                'absolute p-2 min-h-10 flex flex-col gap-4 bg-white dark:bg-dark',
                'w-full top-0 left-0 h-screen origin-top-left',
                'sm:w-96 sm:top-full sm:mt-2 sm:h-auto sm:rounded-lg sm:shadow-lg sm:bg-white sm:border sm:border-slate-300',
                'sm:dark:border-slate-700 sm:dark:bg-dark',
                'lg:left-auto lg:right-0 lg:origin-top-right',
              )}
            >
              {isLoading ? (
                <CircularLoader />
              ) : (
                <>
                  {isMobile && (
                    <div className='p-2'>
                      <UserMenuButton open={open} />
                    </div>
                  )}

                  <div className='flex justify-between items-start p-2'>
                    <H3 extraClassName='truncate'>{sessionUserName}</H3>
                    <div className='flex flex-col items-center gap-2'>
                      <LanguageChanger />

                      <ThemeChanger />
                    </div>
                  </div>

                  <div>
                    {showMyProfileLink && (
                      <UserMenuItem
                        isLink={true}
                        pathname={ROUTE.user.profile}
                        label={t('common:header.dropdown.my-profile')}
                        icon={<RiUser3Fill />}
                      />
                    )}

                    {showMyTeamInfoLink && (
                      <UserMenuItem
                        isLink={true}
                        pathname={ROUTE.manager.teamInfo}
                        label={t('common:header.dropdown.my-team-info')}
                        icon={<RiTeamFill />}
                      />
                    )}

                    {showProfileDirectoryLink && (
                      <UserMenuItem
                        isLink={true}
                        pathname='/people-directory'
                        label={t('common:sidebar.people-directory')}
                        icon={<RiBuilding4Fill />}
                      />
                    )}

                    {showAdministrationPanel && (
                      <>
                        <span className='block my-2 w-full h-px bg-slate-300' />

                        <AdministrationPanel />
                      </>
                    )}

                    <span className='block my-2 w-full h-px bg-slate-300' />

                    <UserMenuItem
                      isLink={false}
                      onClick={() => {
                        if (session) defaultSignOut()
                        else defaultSignIn()
                      }}
                      label={
                        session
                          ? t('common:header.dropdown.sign-out')
                          : t('common:header.dropdown.sign-in')
                      }
                      icon={session ? <RiLogoutCircleLine /> : <RiLoginCircleLine />}
                    />
                  </div>
                </>
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
