import { I18n } from 'next-translate'

export const LOCALES = ['en', 'fr']
export const PREFERRED_LOCALE_COOKIE = 'NEXT_LOCALE'
export const DEFAULT_LOCALE = 'en'
export enum LOCALES_ENUM {
  EN = 'en',
  FR = 'fr',
}
export type I18nWithLangTyped = I18n & { lang: LOCALES_ENUM }
