import { Portal } from '@headlessui/react'
import { ReactNode } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { PageType } from '@elendi/util-types'

import { H1 } from '@elendi/ui-typography'

import { Breadcrumbs, CrumbBase } from './Breadcrumbs'
import { LayoutWrapper } from './LayoutWrapper'
import { PageTitleHead } from './PageTitleHead'

export const Layout = ({
  h1,
  title,
  breadcrumbs = [],
  children,
  pageType,
  isSigninProcess = false,
  hideContent = false,
  hideCopyright = false,
}: {
  h1?: string
  title: string
  breadcrumbs?: Array<CrumbBase>
  pageType: PageType
  children: ReactNode
  isSigninProcess?: boolean
  hideContent?: boolean
  hideCopyright?: boolean
}) => {
  const showBreadcrumbs = breadcrumbs.length > 1

  return (
    <>
      <PageTitleHead title={title} />

      <div className='flex h-screen overflow-hidden z-50'>
        <div
          id='main-content'
          className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden whitespace-pre-line'
        >
          <LayoutWrapper
            pageType={pageType}
            breadcrumbs={breadcrumbs}
            hideContent={isSigninProcess || hideContent}
          >
            {showBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}

            {h1 && <H1>{h1}</H1>}

            <div className='flex flex-col gap-8 xl:gap-16'>{children}</div>

            {!hideCopyright && (
              <div className='pt-8 flex justify-center text-text-light font-light'>{`© elendi`}</div>
            )}
          </LayoutWrapper>
        </div>
      </div>

      <Portal>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} theme='colored' />
      </Portal>
    </>
  )
}
