import Link from 'next/link'
import { useRouter } from 'next/router'

import { ROUTE } from '@elendi/util-endpoints'
import { PageType } from '@elendi/util-types'

import { maintainImpersonate } from '@elendi/feature-authentication/utils'
import { useUserInfos } from '@elendi/feature-user-profile/hooks'

type TopSideBrandProps = {
  pageType: PageType
}
export const TopSideBrand = ({ pageType }: TopSideBrandProps) => {
  const { query } = useRouter()
  const { isLoading, isError } = useUserInfos()

  const logo = <ElendiLogo />

  if (isLoading || isError) return <Link href='/'>{logo}</Link>

  const employeePathname = ROUTE.user.home
  const managerPathname = ROUTE.manager.home
  const pathname = pageType === PageType.ENTERPRISE_MANAGER ? managerPathname : employeePathname

  return <Link href={{ pathname, query: maintainImpersonate({ query }) }}>{logo}</Link>
}

export const ElendiLogo = () => (
  <img
    src='/images/logos/elendi/logo-sidebar.png'
    alt='elendi Logo'
    style={{
      filter:
        'brightness(0) saturate(100%) invert(15%) sepia(70%) saturate(4719%) hue-rotate(355deg) brightness(86%) contrast(85%)',
    }}
  />
)
