export const isSpecificBatchPathname = (pathname: string) => {
  const noLocalePathname =
    pathname.startsWith('/fr') || pathname.startsWith('/en') ? pathname.slice(3) : pathname
  const isMyOrganization = noLocalePathname.startsWith('/my-organization')
  if (!isMyOrganization) return false
  const rest = noLocalePathname.slice('/my-organization'.length)
  if (
    rest.startsWith('/profile') ||
    rest.startsWith('/drilldown') ||
    rest.startsWith('/pulse') ||
    rest.startsWith('/handbook') ||
    rest.length <= 1
  )
    return false
  return true
}
