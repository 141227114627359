import { compareDesc } from 'date-fns/compareDesc'
import { Translate } from 'next-translate'
import { ReactNode } from 'react'
import {
  RiBook2Fill,
  RiBook2Line,
  RiCommunityFill,
  RiCommunityLine,
  RiDashboard3Fill,
  RiDashboard3Line,
  RiDiscussFill,
  RiDiscussLine,
  RiHeartPulseFill,
  RiHeartPulseLine,
  RiHome4Fill,
  RiHome4Line,
  RiListOrdered2,
  RiMessage3Fill,
  RiMessage3Line,
  RiOpenArmFill,
  RiOpenArmLine,
} from 'react-icons/ri'

import { ROUTE } from '@elendi/util-endpoints'
import { LOCALES_ENUM, Role } from '@elendi/util-shared-constants'

import { SurveyModule, UserSurveyModulesJoined } from '@elendi/feature-survey-modules/shared'
import { getCompulsoryText } from '@elendi/feature-surveys/shared'

export type NavbarLink = {
  href: string
  label: string
  icon: (isActive: boolean) => ReactNode
  show: boolean | undefined
  level: number
  highlight?: boolean
}

type RequireKeys<T extends object, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>

export const getEmployeeLinks = ({
  t,
  canAccessCapabilitiesDashboards,
  canAccessUserPages,
  canAccessAiCompanion,
  email,
  userSurveyModules,
  locale,
}: {
  t: Translate
  canAccessCapabilitiesDashboards: boolean | undefined
  canAccessUserPages: boolean | undefined
  canAccessAiCompanion: boolean | undefined
  email: string | undefined
  userSurveyModules?: UserSurveyModulesJoined | null
  locale?: LOCALES_ENUM
}): Array<NavbarLink> => {
  const navbarModules =
    userSurveyModules?.surveyModules
      .filter((sm): sm is RequireKeys<SurveyModule, 'navbar'> => !!sm.navbar && !sm.isClosed)
      .sort((a, b) => compareDesc(a.createdAt, b.createdAt)) ?? []
  const showCore = canAccessUserPages

  const modules = navbarModules.map(
    ({ slug, navbar, icon, _id }, idx): NavbarLink => ({
      href: `${ROUTE.user.surveyModule}/${slug}`,
      label: getCompulsoryText(navbar.title, locale ?? LOCALES_ENUM.EN),
      icon: () => <div className='w-5 h-5' dangerouslySetInnerHTML={{ __html: icon.svg }} />,
      show: true,
      level: 10 + idx,
      highlight: userSurveyModules
        ? !userSurveyModules.done.some((sm) => sm.toHexString() === _id.toHexString())
        : false,
    }),
  )

  return [
    ...modules,
    {
      href: ROUTE.user.home,
      label: t('common:sidebar.my-dashboard'),
      icon: (isActive) => (isActive ? <RiHome4Fill /> : <RiHome4Line />),
      show: true,
      level: 0,
    },
    {
      href: ROUTE.user.aiCompanion,
      label: t('common:sidebar.ai-companion'),
      icon: (isActive) => (isActive ? <RiMessage3Fill /> : <RiMessage3Line />),
      show: canAccessAiCompanion,
      level: 1,
      highlight: true,
    },
    {
      href: ROUTE.user.capabilities,
      label: t('common:sidebar.capabilities'),
      icon: (isActive) => (isActive ? <RiDashboard3Fill /> : <RiDashboard3Line />),
      show: canAccessCapabilitiesDashboards,
      level: 2,
    },
    {
      href: ROUTE.user.performanceInterview,
      label: t('common:sidebar.annual-performance'),
      icon: (_) => <RiListOrdered2 />,
      show: showCore,
      level: 3,
    },
    {
      href: ROUTE.user.developmentPlan,
      label: t('common:sidebar.development-plan'),
      icon: (isActive) => (isActive ? <RiBook2Fill /> : <RiBook2Line />),
      show: showCore,
      level: 4,
    },
    {
      href: ROUTE.user.pulse,
      label: t('common:sidebar.pulse'),
      icon: (isActive) => (isActive ? <RiHeartPulseFill /> : <RiHeartPulseLine />),
      show: showCore,
      level: 5,
    },
    {
      href: ROUTE.user.feedbacks,
      label: t('common:sidebar.kudos'),
      icon: (isActive) => (isActive ? <RiDiscussFill /> : <RiDiscussLine />),
      show: showCore,
      level: 6,
    },
  ]
}

export const getManagerLinks = ({
  t,
  canAccessCompanyMyOrganization,
  is_manager,
  isHr,
  isSuperManager,
  isAdminBool,
  canAccessStructuralEngagementReport,
  canAccessNovadeEngagement,
  canAccessMyOrganizationAsHr,
  canAccessManagerPage,
}: {
  t: Translate
  canAccessCompanyMyOrganization: boolean | undefined
  is_manager: boolean | null | undefined
  isHr: boolean | undefined
  isSuperManager: boolean | null | undefined
  isAdminBool: boolean | undefined
  canAccessStructuralEngagementReport: boolean | undefined
  canAccessNovadeEngagement: boolean | undefined
  canAccessMyOrganizationAsHr: boolean | undefined
  canAccessManagerPage: boolean | undefined
}): Array<NavbarLink> => {
  return [
    {
      href: ROUTE.manager.home,
      label: t('common:sidebar.home'),
      icon: (isActive) => (isActive ? <RiHome4Fill /> : <RiHome4Line />),
      show: is_manager || isHr || isSuperManager || isAdminBool || canAccessManagerPage,
      level: 0,
    },
    {
      href: ROUTE.manager.capabilities,
      label: t('common:sidebar.my-organization'),
      icon: (isActive) => (isActive ? <RiDashboard3Fill /> : <RiDashboard3Line />),
      show: canAccessCompanyMyOrganization || isAdminBool || canAccessMyOrganizationAsHr,
      level: 1,
    },
    {
      href: ROUTE.manager.performanceInterviewDashboard,
      label: t('common:sidebar.annual-performance-dashboard'),
      icon: (_) => <RiListOrdered2 />,
      show: is_manager || isHr || isSuperManager || isAdminBool,
      level: 5,
    },
    {
      href: ROUTE.manager.developmentPlan,
      label: t('common:sidebar.devplan-dashboard'),
      icon: (isActive) => (isActive ? <RiBook2Fill /> : <RiBook2Line />),
      show: canAccessCompanyMyOrganization || is_manager || isHr || isSuperManager || isAdminBool,
      level: 6,
    },
    {
      href: ROUTE.manager.strutcuralEngagement,
      label: t('common:sidebar.engagement'),
      icon: (isActive: boolean) => (isActive ? <RiOpenArmFill /> : <RiOpenArmLine />),
      show: canAccessStructuralEngagementReport || isAdminBool,
      level: 2,
    },
    {
      href: '/engagement/novade',
      label: t('common:sidebar.engagement-historic-novade'),
      icon: (isActive) => (isActive ? <RiOpenArmFill /> : <RiOpenArmLine />),
      show: canAccessNovadeEngagement,
      level: 8,
    },
    {
      href: ROUTE.manager.pulse,
      label: t('common:sidebar.pulse'),
      icon: (isActive: boolean) => (isActive ? <RiHeartPulseFill /> : <RiHeartPulseLine />),
      show: canAccessCompanyMyOrganization || isAdminBool || canAccessMyOrganizationAsHr,
      level: 1,
    },
  ]
}

export const getStudentsLinks = (t: Translate): Array<NavbarLink> => {
  return [
    {
      href: '/dashboard',
      label: t('common:sidebar.my-dashboard'),
      icon: (isActive) => (isActive ? <RiDashboard3Fill /> : <RiDashboard3Line />),
      show: true,
      level: 0,
    },
  ]
}

export const getSchoolsLinks = ({
  canAccessStudentViewer,
  isAdminBool,
}: {
  canAccessStudentViewer: boolean | undefined
  isAdminBool: boolean | undefined
}): Array<NavbarLink> => {
  return [
    {
      href: '/school',
      label: 'Administration',
      icon: (isActive) => (isActive ? <RiCommunityFill /> : <RiCommunityLine />),
      show: canAccessStudentViewer || isAdminBool,
      level: 0,
    },
  ]
}

export const getLowestLevelAccessibleLink = (links: Array<NavbarLink>): null | string => {
  const accessibleLinks = links.filter(({ show }) => !!show)
  if (accessibleLinks.length === 0) return null
  const firstLink = accessibleLinks.sort((lA, lB) => lA.level - lB.level)[0]
  return firstLink.href
}

const isCurrentCustomer = (email: string | null | undefined): boolean => {
  if (!email) return false

  return ['@verkor.com', '@novade.net', '@calyxia.com', '@groupeastek.fr', 'astek@elendi.ai'].some(
    (ending) => email.endsWith(ending),
  )
}

const isAtLeastUser = (role: Role | undefined): boolean => {
  if (!role) return false

  return ![Role.UNVERIFIED, Role.PEER].includes(role)
}
