import { RefAttributes, useEffect, useState } from 'react'
import Select, { GroupBase, Props, SelectInstance } from 'react-select'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'

export const ReactSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group> & RefAttributes<SelectInstance<Option, IsMulti, Group>>,
) => {
  const [isClient, setIsClient] = useState(false) // disables ssr for this component

  useEffect(() => setIsClient(true), [])

  if (isClient) {
    return (
      <Select
        {...props}
        classNamePrefix={props.classNamePrefix ? props.classNamePrefix : 'my-react-select'}
        className={
          props.className
            ? `my-react-select-container ${props.className}`
            : 'my-react-select-container'
        }
        // DIRTY https://github.com/JedWatson/react-select/issues/5816
        ariaLiveMessages={{
          guidance: () => '',
          onChange: () => '',
          onFilter: () => '',
          onFocus: () => '',
        }}
      />
    )
  }

  return null
}

export const CreatableReactSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: CreatableProps<Option, IsMulti, Group> &
    RefAttributes<SelectInstance<Option, IsMulti, Group>>,
) => {
  const [isClient, setIsClient] = useState(false) // disables ssr for this component

  useEffect(() => setIsClient(true), [])

  if (isClient) {
    return (
      <CreatableSelect
        {...props}
        classNamePrefix={props.classNamePrefix ? props.classNamePrefix : 'my-react-select'}
        className={
          props.className
            ? `my-react-select-container ${props.className}`
            : 'my-react-select-container'
        }
        // DIRTY https://github.com/JedWatson/react-select/issues/5816
        ariaLiveMessages={{
          guidance: () => '',
          onChange: () => '',
          onFilter: () => '',
          onFocus: () => '',
        }}
      />
    )
  }

  return null
}
