import { LOCALES_ENUM } from './i18n'

export const CAPABILITY_REVIEW_QUESTION_REF = [
  'apps-frontend-12V',
  'test--de-bug--review-Vpeers',
  'api-7-validation',
  'api-11-validation',
  'algorithms-code-structure-and-paradigms-2V',
  'cicd-5',
  'security--compliance-V',
]

export enum Locale {
  EN = 'en',
  FR = 'fr',
  DE = 'de',
  NL = 'nl',
  HE = 'he',
  RU = 'ru',
}

export const isRtl = (locale: Locale | LOCALES_ENUM | undefined): boolean => locale === Locale.HE
