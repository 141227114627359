import { Session } from 'next-auth'

import { IS_TRACKING_ENABLED } from './util-tracking'

export const trackError = (session: Session | null, statusCode: number) => {
  if (IS_TRACKING_ENABLED) {
    const email = session?.user?.email ?? 'unauthenticated'

    console.log(`Error has been triggered`, { email, statusCode })
    console.log(`mixpanel.people.set({ lastConnection: new Date() })`)
    console.log(`mixpanel.people.set_once({ firstConnection: new Date() })`)
  }
}
