import { z } from 'zod'

import { LOCALES_ENUM, Locale } from '@elendi/util-shared-constants'

export const getLocaleFromString = (lang: string): LOCALES_ENUM =>
  lang === 'fr' ? LOCALES_ENUM.FR : LOCALES_ENUM.EN

export const getExpandedLocaleFromString = (lang: string | undefined): Locale | undefined => {
  const localeParse = z.nativeEnum(Locale).safeParse(lang)

  if (!localeParse.success) return undefined

  return localeParse.data
}
