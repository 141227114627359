import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { ROUTE } from '@elendi/util-endpoints'
import { EDUCATION_PAGES, PageType, TeamMemberType } from '@elendi/util-types'

import { maintainImpersonate } from '@elendi/feature-authentication/utils'
import { useUserInfos } from '@elendi/feature-user-profile/hooks'

import { getEmployeeLinks, getLowestLevelAccessibleLink, getManagerLinks } from './Links'
import { ViewSwitch } from './ViewSwitch'

type ManagerViewSwitchProps = {
  pageType: PageType
}
export const ManagerViewSwitch = ({ pageType }: ManagerViewSwitchProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { userInfos, isLoading: userInfosIsLoading, isError: userInfosIsError } = useUserInfos()

  if (EDUCATION_PAGES.includes(pageType)) return null

  const {
    isAdmin: isAdminBool,
    canAccessStructuralEngagementReport,
    email,
    canAccessNovadeEngagement,
    canAccessCapabilitiesDashboards,
    canAccessCompanyMyOrganization,
    canAccessMyOrganizationAsHr,
    canAccessUserPages,
    canAccessAiCompanion,
  } = userInfos ?? {}
  const is_manager = TeamMemberType.ENTERPRISE === userInfos?.type ? userInfos.is_manager : false
  const canAccessManagerPage = userInfos?.canAccessManagerPage
  const isHr = TeamMemberType.ENTERPRISE === userInfos?.type ? userInfos.isHr : false
  const isSuperManager =
    TeamMemberType.ENTERPRISE === userInfos?.type ? userInfos.isSuperManager : false
  const userIsAtLeastManager =
    is_manager || isHr || isSuperManager || isAdminBool || canAccessManagerPage

  if (userInfosIsLoading || userInfosIsError || !userIsAtLeastManager) return null

  const pathname =
    getSwitchLinkHref({
      t,
      pageType,
      canAccessCompanyMyOrganization,
      is_manager,
      isHr,
      isSuperManager,
      isAdminBool,
      canAccessStructuralEngagementReport,
      email,
      canAccessNovadeEngagement,
      canAccessCapabilitiesDashboards,
      canAccessMyOrganizationAsHr,
      canAccessManagerPage,
      canAccessUserPages,
      canAccessAiCompanion,
    }) ?? ROUTE.user.home

  return (
    <ViewSwitch
      href={{ pathname, query: maintainImpersonate({ query: router.query }) }}
      text={t('common:header.manager-view')}
      checked={pageType === PageType.ENTERPRISE_MANAGER}
    />
  )
}

const getSwitchLinkHref = ({
  t,
  pageType,
  canAccessCompanyMyOrganization,
  is_manager,
  isHr,
  isSuperManager,
  isAdminBool,
  canAccessStructuralEngagementReport,
  email,
  canAccessNovadeEngagement,
  canAccessCapabilitiesDashboards,
  canAccessMyOrganizationAsHr,
  canAccessManagerPage,
  canAccessUserPages,
  canAccessAiCompanion,
}: {
  t: Translate
  pageType: PageType
  canAccessCompanyMyOrganization: boolean | undefined
  is_manager: boolean | null | undefined
  isHr: boolean | undefined
  isSuperManager: boolean | null | undefined
  isAdminBool: boolean | undefined
  canAccessStructuralEngagementReport: boolean | undefined
  email: string | undefined
  canAccessNovadeEngagement: boolean | undefined
  canAccessCapabilitiesDashboards: boolean | undefined
  canAccessMyOrganizationAsHr: boolean | undefined
  canAccessManagerPage: boolean | undefined
  canAccessUserPages: boolean | undefined
  canAccessAiCompanion: boolean | undefined
}) => {
  switch (pageType) {
    case PageType.ENTERPRISE_MANAGER:
      return getLowestLevelAccessibleLink(
        getEmployeeLinks({
          t,
          canAccessCapabilitiesDashboards,
          canAccessAiCompanion,
          canAccessUserPages,
          email,
        }),
      )
    case PageType.ENTERPRISE_USER:
      return getLowestLevelAccessibleLink(
        getManagerLinks({
          t,
          canAccessCompanyMyOrganization,
          is_manager,
          isHr,
          isSuperManager,
          isAdminBool,
          canAccessStructuralEngagementReport,
          canAccessNovadeEngagement,
          canAccessMyOrganizationAsHr,
          canAccessManagerPage,
        }),
      )
    default:
      return null
  }
}
