import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { RiArrowGoBackLine, RiMenuLine, RiUser3Fill, RiUser3Line } from 'react-icons/ri'

type UserMenuButtonProps = {
  open: boolean
}
export const UserMenuButton = ({ open }: UserMenuButtonProps) => {
  return (
    <Menu.Button
      className={classNames(
        'group px-5 py-2 rounded-lg text-lg text-white bg-gradient-to-r',
        open ? 'from-slate-600/90 to-slate-700/90' : 'from-slate-600 to-slate-700',
        'hover:from-slate-600/90 hover:to-slate-700/90',
      )}
    >
      <RiArrowGoBackLine className={`sm:hidden ${!open && 'hidden'}`} />
      <RiMenuLine className={`lg:hidden ${open && 'hidden sm:block'}`} />
      <RiUser3Line className={`hidden ${!open && 'lg:block lg:group-hover:hidden'}`} />
      <RiUser3Fill className={`hidden lg:group-hover:block ${open && 'lg:block'}`} />
    </Menu.Button>
  )
}
