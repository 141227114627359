import classNames from 'classnames'

type Widthes = 'short' | 'medium' | 'lg' | 'long' | 'md' | 'sm' | 'xl'
type Heights = 'small' | 'medium' | 'lg' | 'big' | 'md' | 'sm' | 'xl'

export const SkeletonLoader = ({
  width = 'md',
  height = 'md',
  isInline = false,
}: {
  height?: Heights
  width?: Widthes
  isInline?: boolean
}) => {
  const widthValues: Record<Widthes, string> = {
    short: '6rem',
    sm: '6rem',
    medium: '8rem',
    md: '8rem',
    lg: '12rem',
    long: '16rem',
    xl: '16rem',
  }
  const heightValues: Record<Heights, string> = {
    small: '0.5rem',
    sm: '0.5rem',
    medium: '1rem',
    md: '1rem',
    big: '2rem',
    lg: '2rem',
    xl: '4rem',
  }

  const style = {
    width: widthValues[width],
    height: heightValues[height],
    lg: '12rem',
    long: '16rem',
    xl: '16rem',
  }

  if (isInline)
    return (
      <span
        className={classNames('inline-block', 'bg-slate-200 animate-pulse rounded-full')}
        style={style}
      />
    )

  return <div className={classNames('bg-slate-200 animate-pulse rounded-full')} style={style} />
}

export const SkeletonParagraph = () => (
  <div className='flex flex-wrap gap-2'>
    <SkeletonLoader height='small' width='short' />
    <SkeletonLoader height='small' />
    <SkeletonLoader height='small' />
    <SkeletonLoader height='small' width='long' />
  </div>
)

export const SkeletonCircle = ({ radius }: { radius: number }) => (
  <div
    className={classNames('bg-slate-200 animate-pulse rounded-full')}
    style={{ width: `${radius}px`, height: `${radius}px` }}
  />
)

export const SelectLoader = () => (
  <div className={classNames('bg-white animate-pulse rounded-lg w-32 h-10')} />
)
