import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { MdAdminPanelSettings } from 'react-icons/md'

import { getAdminHostname } from '@elendi/util-endpoints'

import { getImpersonateEmail } from '@elendi/feature-authentication/utils'

import { ImpersonateSelect } from './ImpersonateSelect'

export const AdministrationPanel = () => {
  const { t } = useTranslation()
  const { query } = useRouter()
  const impersonatedEmail = getImpersonateEmail(query)

  const adminHostname = getAdminHostname()

  return (
    <>
      <div className='mb-2'>
        <ImpersonateSelect impersonatedEmail={impersonatedEmail} />
      </div>

      <Menu.Item>
        {({ active }) => (
          <a
            href={`https://${adminHostname}`}
            target='_blank'
            rel='noreferrer'
            className={classNames(
              'w-full flex items-center gap-2 px-2 py-1 rounded-md text-left',
              active && 'bg-gradient-to-r from-amber-500 to-amber-600 text-white',
            )}
          >
            <MdAdminPanelSettings />
            <span className='flex-1'>{t('common:header.dropdown.administration')}</span>
          </a>
        )}
      </Menu.Item>
    </>
  )
}
