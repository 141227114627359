import axios from 'axios'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { RiCloseCircleFill, RiCloseCircleLine } from 'react-icons/ri'
import { SingleValue } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { debounce } from 'ts-debounce'

import { API } from '@elendi/util-endpoints'

import { APIResult, readAxiosResponse } from '@elendi/feature-superjson-api'

export const ImpersonateSelect = ({ impersonatedEmail }: { impersonatedEmail: string | null }) => {
  const router = useRouter()

  const getOptions = async (
    inputValue: string,
  ): Promise<Array<{ value: string; label: string }>> => {
    try {
      const results = await readAxiosResponse<{ emails: Array<string> }>(
        axios.get<APIResult>(API.companyDirectory.getSearchResults, {
          params: { search: inputValue },
        }),
      )
      return results.emails.map((e) => ({ value: e, label: e }))
    } catch (error) {
      return []
    }
  }

  return (
    <div className='flex gap-2'>
      <AsyncCreatableSelect
        className='my-react-select-container flex-1'
        classNamePrefix='my-react-select'
        cacheOptions
        defaultOptions
        placeholder={impersonatedEmail}
        loadOptions={debounce(getOptions, 600) as any}
        onChange={(newValue: SingleValue<{ value: string; label: string }>) => {
          if (!newValue) return

          router.push({
            pathname: router.pathname,
            query: { impersonate: encodeURI(newValue.value) },
          })
        }}
      />

      <button
        className={classNames(
          'group text-lg px-3 rounded-md',
          !impersonatedEmail && 'hidden',
          'hover:bg-gradient-to-r hover:from-slate-600 hover:to-slate-700 hover:text-white',
        )}
        onClick={() => router.push({ pathname: router.pathname })}
      >
        <RiCloseCircleLine className='group-hover:hidden' />

        <RiCloseCircleFill className='hidden group-hover:block' />
      </button>
    </div>
  )
}
