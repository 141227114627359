import { LOCALES_ENUM, Locale } from '@elendi/util-shared-constants'

import { type LocalizedText, LocalizedTextCompulsory } from '../types/shared/LocalizedText'

export const getCompulsoryText = (
  text: LocalizedTextCompulsory,
  locale: Locale | LOCALES_ENUM | undefined,
): string => (!locale ? text.en : (text[locale] ?? text.en))

export const getQuestionLabelDetail = <
  T extends { label: LocalizedTextCompulsory; details: LocalizedText },
>(
  question: T,
  locale: Locale | LOCALES_ENUM,
) => {
  const label = getCompulsoryText(question.label, locale)
  const details = question.details[locale]

  if (!details) return label

  return `${label} ${details}`
}
