import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

import { ROUTE } from '@elendi/util-endpoints'
import { trackError } from '@elendi/util-tracking'
import { PageType } from '@elendi/util-types'

import { Layout } from '@elendi/ui-layout'

const NotFoundImage = (
  <svg
    width='176'
    height='176'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <defs>
      <rect id='a' x='0' y='0' width='176' height='176' rx='88' />
      <rect id='e' x='142' y='211' width='118' height='118' rx='4' />
      <rect id='h' x='142' y='69' width='118' height='118' rx='4' />
      <rect id='j' x='0' y='0' width='118' height='118' rx='4' />
      <rect id='l' x='0' y='142' width='118' height='148' rx='4' />
      <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='b'>
        <stop stopColor='#F3F5F9' offset='0%' />
        <stop stopColor='#E2E8F0' offset='100%' />
      </linearGradient>
      <linearGradient x1='50%' y1='100%' x2='50%' y2='30.355%' id='f'>
        <stop stopColor='#F8FAFC' offset='0%' />
        <stop stopColor='#FFF' offset='100%' />
      </linearGradient>
      <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='m'>
        <stop stopColor='#A5B4FC' offset='0%' />
        <stop stopColor='#818CF8' offset='100%' />
      </linearGradient>
      <linearGradient x1='50%' y1='7.522%' x2='50%' y2='100%' id='n'>
        <stop stopColor='#4338CA' offset='0%' />
        <stop stopColor='#6366F1' stopOpacity='0' offset='100%' />
      </linearGradient>
      <filter
        x='-5.9%'
        y='-4.2%'
        width='111.9%'
        height='111.9%'
        filterUnits='objectBoundingBox'
        id='d'
      >
        <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation='2' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feComposite
          in='shadowBlurOuter1'
          in2='SourceAlpha'
          operator='out'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          values='0 0 0 0 0.0588235294 0 0 0 0 0.0901960784 0 0 0 0 0.164705882 0 0 0 0.06 0'
          in='shadowBlurOuter1'
        />
      </filter>
      <filter
        x='-5.9%'
        y='-4.2%'
        width='111.9%'
        height='111.9%'
        filterUnits='objectBoundingBox'
        id='g'
      >
        <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation='2' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feComposite
          in='shadowBlurOuter1'
          in2='SourceAlpha'
          operator='out'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          values='0 0 0 0 0.0588235294 0 0 0 0 0.0901960784 0 0 0 0 0.164705882 0 0 0 0.06 0'
          in='shadowBlurOuter1'
        />
      </filter>
      <filter
        x='-5.9%'
        y='-4.2%'
        width='111.9%'
        height='111.9%'
        filterUnits='objectBoundingBox'
        id='i'
      >
        <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation='2' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feComposite
          in='shadowBlurOuter1'
          in2='SourceAlpha'
          operator='out'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          values='0 0 0 0 0.0588235294 0 0 0 0 0.0901960784 0 0 0 0 0.164705882 0 0 0 0.06 0'
          in='shadowBlurOuter1'
        />
      </filter>
      <filter
        x='-5.9%'
        y='-3.4%'
        width='111.9%'
        height='109.5%'
        filterUnits='objectBoundingBox'
        id='k'
      >
        <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation='2' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feComposite
          in='shadowBlurOuter1'
          in2='SourceAlpha'
          operator='out'
          result='shadowBlurOuter1'
        />
        <feColorMatrix
          values='0 0 0 0 0.0588235294 0 0 0 0 0.0901960784 0 0 0 0 0.164705882 0 0 0 0.06 0'
          in='shadowBlurOuter1'
        />
      </filter>
    </defs>
    <g fill='none' fillRule='evenodd'>
      <mask id='c' fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
      <use fill='url(#b)' xlinkHref='#a' />
      <g mask='url(#c)'>
        <g transform='rotate(-20 -150.722 164.706)'>
          <use fill='#000' filter='url(#d)' xlinkHref='#e' />
          <rect
            strokeOpacity='.8'
            stroke='#CBD5E1'
            strokeLinejoin='bevel'
            fill='url(#f)'
            x='142.5'
            y='211.5'
            width='117'
            height='117'
            rx='4'
          />
        </g>
        <g transform='rotate(-20 -150.722 164.706)'>
          <use fill='#000' filter='url(#g)' xlinkHref='#h' />
          <rect
            strokeOpacity='.8'
            stroke='#CBD5E1'
            strokeLinejoin='bevel'
            fill='url(#f)'
            x='142.5'
            y='69.5'
            width='117'
            height='117'
            rx='4'
          />
        </g>
        <g transform='rotate(-20 -150.722 164.706)'>
          <use fill='#000' filter='url(#i)' xlinkHref='#j' />
          <rect
            strokeOpacity='.8'
            stroke='#CBD5E1'
            strokeLinejoin='bevel'
            fill='url(#f)'
            x='.5'
            y='.5'
            width='117'
            height='117'
            rx='4'
          />
        </g>
        <g transform='rotate(-20 -150.722 164.706)'>
          <use fill='#000' filter='url(#k)' xlinkHref='#l' />
          <rect
            strokeOpacity='.8'
            stroke='#CBD5E1'
            strokeLinejoin='bevel'
            fill='url(#f)'
            x='.5'
            y='142.5'
            width='117'
            height='147'
            rx='4'
          />
        </g>
      </g>
      <g mask='url(#c)'>
        <path
          fill='url(#m)'
          d='M43.103 0l42.97 86.267-42.97-13.48L.132 86.267z'
          transform='rotate(-51 93.327 26.607)'
        />
        <path
          fill='url(#n)'
          d='M85.694 85.504h-2.052l-40.54-12.717-.445.14V.895L43.103 0z'
          transform='rotate(-51 93.327 26.607)'
        />
        <path
          d='M115.34 104.24c10.516 7.682 22.413 13.456 34.883 17.051.683.203.858-.412.185-.628-6.138-1.912-11.97-4.83-17.498-8.092-5.542-3.269-10.787-6.87-16.107-10.326-2.145-1.415-3.683.349-1.463 1.995M113.053 118.196c19.255 21.87 38.637 43.728 57.93 65.59 1.058 1.199 1.536.775.49-.433-18.992-21.94-36.863-45.466-56.567-66.794-4-4.33-5.926-2.988-1.853 1.637'
          fillOpacity='.4'
          fill='#475569'
        />
      </g>
    </g>
  </svg>
)

export const ErrorPage = ({
  h1,
  title,
  message,
  pageType,
  statusCode,
}: {
  h1: string
  title: string
  message: string
  pageType: PageType
  statusCode: number
}) => {
  const { t } = useTranslation()
  const { data: session, status } = useSession()
  if (status === 'authenticated' || status === 'unauthenticated') trackError(session, statusCode)

  return (
    <Layout h1={h1} title={title} pageType={pageType}>
      <div className='flex h-screen overflow-hidden'>
        <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
          <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
            <div className='max-w-2xl m-auto mt-16'>
              <div className='text-center px-4'>
                <div className='inline-flex mb-8'>{NotFoundImage}</div>
                <div className='mb-6'>{message}</div>
                <div className='btn bg-indigo-500 hover:bg-indigo-600 text-white'>
                  <Link href={ROUTE.user.home}>{t('common:error-page.back-to-dashboard')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
