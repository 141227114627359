import { z } from 'zod'

import { ObjectId } from '@elendi/util-objectid'

export const zodObjectId = z.preprocess((val, context) => {
  try {
    return new ObjectId(String(val))
  } catch (error) {
    throw new Error(
      `Could not parse value as ObjectId (zodObjectId) path: ${JSON.stringify(context.path)}`,
    )
  }
}, z.instanceof(ObjectId))
