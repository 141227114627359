import { z } from 'zod'

import { zodObjectId } from './z-object-id'

export const PersonInfoZod = z.object({
  email: z.string(),
  name: z.string(),
})

export enum TeamMemberType {
  STUDENT = 'STUDENT',
  ENTERPRISE = 'ENTERPRISE',
  TEACHER = 'TEACHER',
}

export enum StudentStatus {
  ALTERNANT = 'ALTERNANT',
  IN_CLASS = 'IN_CLASS',
}

export enum Civility {
  MISTER = 'MISTER',
  MISSES = 'MISSES',
}

export enum PositionLevel {
  INTERNSHIP = 'internship',
  JUNIOR = 'junior',
  SENIOR = 'senior',
  MANAGER = 'manager',
  SUPER_MANAGER = 'super-manager',
  C_LEVEL = 'c-level',
  UNKNOWN = 'unknown',
}

const PositionZod = z.object({
  title: z.string(),
  level: z.union([z.nativeEnum(PositionLevel), z.null()]),
  department: z.string().nullable(),
})

export const NameZod = z.object({
  full_name: z.string().optional(),
  last_name: z.string().min(1),
  first_name: z.string().min(1),
})

export const TeamMemberBooleansAccessesZod = z.object({
  isHr: z.boolean().optional(),
  canAccessCapabilitiesDashboards: z.boolean().optional(),
  canAccessCompanyMyOrganization: z.boolean().optional(),
  canSeeOrganizationalChart: z.boolean().optional(),
  canAccessStructuralEngagementReport: z.boolean().optional(),
  canAccessNovadeEngagement: z.boolean().optional(),
  canAccessMyOrganizationAsHr: z.boolean().optional(),
  canCreateMandatoryTraining: z.boolean().optional(),
  canAccessEnpsSplits: z.boolean().optional(),
  canAccessPeopleDirectory: z.boolean().optional(),
  canAccessCompanyProgress: z.boolean().optional(),
  canAccessAIReadyGlobalResults: z.boolean().optional(),
  canAccessSurveyEditor: z.boolean().optional(),
  canAccessManagerPage: z.boolean().optional(),
  canAccessUserPages: z.boolean().optional(),
  canAccessAiCompanion: z.boolean().optional(),
  canAccessForfaitJourInterviews: z.boolean().optional(),
})

export const TeamMemberBooleansAccesses = TeamMemberBooleansAccessesZod.keyof().options

export type TeamMemberBooleansAccessType = (typeof TeamMemberBooleansAccesses)[number]

export const TeamMemberBaseZod = z
  .object({
    _id: zodObjectId,
    name: NameZod.optional(),
    email: z.string(),
    company_name: z.string().optional(),
    phoneNumber: z.string().optional(),
    civility: z.nativeEnum(Civility).optional(),
    birthDate: z.date().optional().nullable(),
    location: z.string().nullable().optional(),
    tags: z.array(z.string()),
    isAdmin: z.boolean().optional(),
  })
  .merge(TeamMemberBooleansAccessesZod)

export const TeamMemberZod = TeamMemberBaseZod.extend({
  type: z.literal(TeamMemberType.ENTERPRISE),
  position: z.union([PositionZod, z.null()]).optional(),
  is_manager: z.union([z.boolean(), z.null()]).optional(),
  isSuperManager: z.union([z.boolean(), z.null()]).optional(),
  has_manager: z.union([z.boolean(), z.null()]).optional(),
  manager: z.string().nullable().optional(),
  team: z.array(z.string()),
  leftCompany: z.boolean().optional(),
  admitted_at: z.date().optional(),
  selfDeclaredManager: z.string().optional(),
  accessibleProfiles: z.string().array().optional(),
})

export const StudentTeamMemberZod = TeamMemberBaseZod.merge(
  z.object({
    type: z.literal(TeamMemberType.STUDENT),
    yearLevel: z.number(),
    course: z.string(),
    cursus: z.string().nullable(),
    status: z.nativeEnum(StudentStatus),
    contract: z
      .object({
        startDate: z.date(),
        endDate: z.date(),
        company: z
          .object({
            name: z.string(),
            phoneNumber: z.string().nullable(),
            contact: z.object({
              civility: z.nativeEnum(Civility).nullable(),
              lastName: z.string().nullable(),
              firstName: z.string().nullable(),
              jobTitle: z.string().nullable(),
              phoneNumber: z.string().nullable(),
              email: z.string().nullable(),
            }),
          })
          .nullable(),
        tutor: z
          .object({
            email: z.string(),
            lastName: z.string(),
            firstName: z.string(),
            civility: z.nativeEnum(Civility).nullable(),
            jobTitle: z.string().nullable(),
            phoneNumber: z.string().nullable(),
          })
          .nullable(),
      })
      .nullable(),
  }),
)

export const TeamMemberNamesZod = TeamMemberZod.pick({ email: true }).and(
  z.object({ firstName: z.string(), lastName: z.string() }),
)

export const TeacherZod = TeamMemberBaseZod.merge(
  z.object({
    type: z.literal(TeamMemberType.TEACHER),
    course: z.string(),
  }),
)

export const AllTeamMemberZod = z.discriminatedUnion('type', [
  TeamMemberZod,
  StudentTeamMemberZod,
  TeacherZod,
])

export const TeamMemberInputZod = z.object({
  name: NameZod.pick({ first_name: true, last_name: true }),
  position: z.object({ title: z.string().min(1) }),
  manager: z.string(),
  admitted_at: z.date(),
})

export type TeamMember = z.infer<typeof TeamMemberZod>
export type AllTeamMember = z.infer<typeof AllTeamMemberZod>
export type StudentTeamMember = z.infer<typeof StudentTeamMemberZod>
export type TeamMemberNames = z.infer<typeof TeamMemberNamesZod>
export type Teacher = z.infer<typeof TeacherZod>
export type TeamMemberInput = z.infer<typeof TeamMemberInputZod>
export type Name = z.infer<typeof NameZod>
export type Position = z.infer<typeof PositionZod>
export type PersonInfo = z.infer<typeof PersonInfoZod>

const DirectoryLineZod = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
})

export const CompanyDirectoryDocZod = z.object({
  _id: zodObjectId,
  company: z.string(),
  directory: z.array(DirectoryLineZod),
})

export type CompanyDirectoryDoc = z.infer<typeof CompanyDirectoryDocZod>
export type DirectoryLine = z.infer<typeof DirectoryLineZod>

export type TeamMemberProfileInfos = {
  email: string
  name: {
    firstName: string
    lastName: string
  }
  position: Position | null
  isManager: boolean
  isFlashing?: boolean
}

export type UseUserInfosApi<T extends AllTeamMember> = {
  userInfos: T | null
  displayEmail: string | null
}
