export enum PageType {
  ENTERPRISE_USER = 'ENTERPRISE_USER',
  ENTERPRISE_MANAGER = 'ENTERPRISE_MANAGER',
  EDUCATION_STUDENT = 'EDUCATION_STUDENT',
  EDUCATION_SCHOOL = 'EDUCATION_SCHOOL',
}

export const ENTERPRISE_PAGES = [PageType.ENTERPRISE_USER, PageType.ENTERPRISE_MANAGER]
export const EDUCATION_PAGES = [PageType.EDUCATION_STUDENT, PageType.EDUCATION_SCHOOL]
export const ORGANIZATION_PAGES = [PageType.ENTERPRISE_MANAGER, PageType.EDUCATION_STUDENT]
export const USER_PAGES = [PageType.ENTERPRISE_USER, PageType.EDUCATION_STUDENT]
