import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

import { isSpecificBatchPathname } from '@elendi/util-constants'
import { PageType } from '@elendi/util-types'

import { ReactSelect } from '@elendi/ui-react-select'

const options = [
  { value: null, label: 'My team' },
  { value: 'aymeric.jacquin@novade.net', label: 'EMEA Tech' },
  { value: 'oceane.madignier@novade.net', label: 'EMEA Services' },
]

// DIRTY super dirty work for custom customer work
export const SpecificImpersonateViewSwitch = ({ pageType }: { pageType: PageType }) => {
  const router = useRouter()
  const { data: session } = useSession()
  const isBruno =
    session?.user?.email === 'bruno.suard@novade.net' ||
    session?.user?.email === 'victor.andrieu@elendi.ai' ||
    session?.user?.email?.endsWith('bruno@elendi.ai')
  const isManagerPage = pageType === PageType.ENTERPRISE_MANAGER

  const hasAccess = isManagerPage && isBruno
  if (!hasAccess) return null

  const { impersonate } = router.query

  const targetPathname = isSpecificBatchPathname(router.pathname)
    ? '/my-organization'
    : router.pathname

  return (
    <ReactSelect
      defaultValue={options.find((opt) => opt.value === impersonate) ?? options[0]}
      options={options}
      onChange={(selected) => {
        const value = selected?.value
        if (!value)
          router.push({
            pathname: targetPathname,
            query: {},
          })
        router.push({
          pathname: targetPathname,
          query: { impersonate: value },
        })
      }}
    />
  )
}
