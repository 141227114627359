import classNames from 'classnames'
import Link from 'next/link'
import { RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri'
import { UrlObject } from 'url'

export const ViewSwitch = ({
  href,
  onClick,
  text,
  checked,
}: {
  href: UrlObject
  onClick?: () => void
  text: string
  checked: boolean
}) => {
  const Checkbox = ({ checked }: { checked: boolean }) =>
    checked ? <RiCheckboxLine /> : <RiCheckboxBlankLine />

  return (
    <Link
      href={href}
      className={classNames(
        'flex items-center gap-2 font-semibold rounded-lg text-sm sm:text-base px-3 py-1 sm:px-5 sm:py-1',
        checked
          ? 'bg-gradient-to-r from-slate-600 to-slate-700 hover:from-slate-600/90 hover:to-slate-700/90 text-white'
          : 'bg-transparent dark:bg-slate-200 hover:bg-slate-700/10 text-slate-700 border border-slate-700',
      )}
      onClick={onClick}
    >
      {text}
      <Checkbox checked={checked} />
    </Link>
  )
}
