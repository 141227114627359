export enum Role {
  LOGGED_OUT = 'LOGGED_OUT',
  UNVERIFIED = 'UNVERIFIED',
  USER = 'USER',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SUPER_MANAGER = 'SUPER_MANAGER',
  HR = 'HR',
  PEER = 'PEER',
}
