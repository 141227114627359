import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { MouseEvent } from 'react'

import { maintainImpersonate } from '@elendi/feature-authentication/utils'

type UserMenuItemProps =
  | {
      isLink: true
      pathname: string
      label: string
      icon: JSX.Element
    }
  | {
      isLink: false
      onClick: (event?: MouseEvent<HTMLElement>) => void
      label: string
      icon: JSX.Element
    }
export const UserMenuItem = (props: UserMenuItemProps) => {
  const { query } = useRouter()

  if (props.isLink) {
    const { pathname, label, icon } = props

    return (
      <Menu.Item>
        {({ active }) => (
          <div>
            <Link
              href={{ pathname, query: maintainImpersonate({ query }) }}
              className={classNames(
                'w-full flex items-center gap-2 px-2 py-1 rounded-md',
                active && 'bg-gradient-to-r from-slate-600 to-slate-700 text-white',
              )}
            >
              {icon}
              {label}
            </Link>
          </div>
        )}
      </Menu.Item>
    )
  } else {
    const { onClick, label, icon } = props

    return (
      <Menu.Item>
        {({ active }) => (
          <div>
            <button
              onClick={onClick}
              className={classNames(
                'w-full flex items-center gap-2 px-2 py-1 rounded-md text-left',
                active && 'bg-gradient-to-r from-slate-600 to-slate-700 text-white',
              )}
            >
              {icon}
              {label}
            </button>
          </div>
        )}
      </Menu.Item>
    )
  }
}
