import { Session } from 'next-auth'
import { NextRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { UrlObject } from 'url'
import url from 'url'

import { isString } from '@elendi/util-helpers'

import { Role } from './authentification'

export const getUserEmail = (query: ParsedUrlQuery, session: Session): string => {
  if (!session?.user?.email) throw new Error('Session has no email or user')

  const role = session.role
  const email = session.user.email

  if (role === Role.ADMIN) return getImpersonateEmail(query) ?? email

  return email
}

export const getImpersonateEmail = (urlQuery: ParsedUrlQuery): string | null => {
  const impersonate = urlQuery['impersonate']

  return typeof impersonate === 'string' ? impersonate : null
}

export const getBrunoImpersonateEmail = (urlQuery: ParsedUrlQuery): string | null => {
  const impersonate = urlQuery['impersonate']
  if (typeof impersonate !== 'string') return null
  if (!['aymeric.jacquin@novade.net', 'oceane.madignier@novade.net'].includes(impersonate))
    return null
  return impersonate
}

export const maintainImpersonate = ({
  query,
  wantedQueries,
}: {
  query: ParsedUrlQuery
  wantedQueries?: UrlObject['query']
}) => {
  const { impersonate } = query

  if (typeof wantedQueries === 'string') {
    throw new RangeError("WE DON'T SUPPORT URL STRING QUERY")
  }
  return impersonate ? { impersonate, ...wantedQueries } : wantedQueries
}

type PushArgs = Parameters<NextRouter['push']>

export const impersonatePush = async (
  router: NextRouter,
  urlArg: PushArgs['0'],
  as?: PushArgs['1'],
  options?: PushArgs['2'],
): Promise<boolean> => {
  const urlObject = isString(urlArg) ? url.parse(urlArg) : urlArg

  return await router.push(
    {
      ...urlObject,
      query: maintainImpersonate({ query: router.query, wantedQueries: urlObject.query }),
    },
    as,
    options,
  )
}

export const pushImpersonate =
  (router: NextRouter): NextRouter['push'] =>
  async (urlArg, as, options) => {
    const urlObject = isString(urlArg) ? url.parse(urlArg) : urlArg

    return await router.push(
      {
        ...urlObject,
        query: maintainImpersonate({ query: router.query, wantedQueries: urlObject.query }),
      },
      as,
      options,
    )
  }
