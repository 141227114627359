import Link from 'next/link'
import { useRouter } from 'next/router'
import { RiHome2Fill, RiServerFill, RiSpyFill } from 'react-icons/ri'

import { ROUTE } from '@elendi/util-endpoints'

import { getImpersonateEmail, maintainImpersonate } from '@elendi/feature-authentication/utils'

export const AdminIndicators = () => {
  const { query } = useRouter()

  const impersonatedEmail = getImpersonateEmail(query)
  const deployEnv = process.env['NEXT_PUBLIC_VERCEL_ENV'] ?? 'local'
  const isStaging = deployEnv !== 'production'

  if (!impersonatedEmail && !isStaging) return null

  const deployIndicatorBackground = {
    development: 'bg-gradient-to-r from-lime-500 to-lime-600',
    preview: 'bg-gradient-to-r from-teal-500 to-teal-600',
    local: 'bg-gradient-to-r from-pink-500 to-pink-600',
  }[deployEnv]

  return (
    <div className='absolute bottom-0 right-0 translate-y-3 mr-4 flex gap-2'>
      {isStaging && (
        <span
          className={`flex items-center gap-1 px-4 rounded-lg ${deployIndicatorBackground} text-xs text-white`}
        >
          <RiServerFill />

          {deployEnv}
        </span>
      )}

      {!!impersonatedEmail && (
        <>
          <span className='flex items-center gap-1 px-4 rounded-lg bg-gradient-to-r from-amber-500 to-amber-600 text-xs text-white'>
            <RiSpyFill />

            {impersonatedEmail}
          </span>

          <Link
            href={{ pathname: ROUTE.user.home, query: maintainImpersonate({ query }) }}
            className='flex items-center justify-denter px-1 rounded-lg bg-purple-600 text-white text-xs hover:bg-blue-600'
          >
            <RiHome2Fill />
          </Link>
        </>
      )}
    </div>
  )
}
