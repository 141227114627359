import axios from 'axios'
import useSWR from 'swr'

import { API } from '@elendi/util-endpoints'
import { useImpersonate } from '@elendi/util-hooks'

import { APIResult, readAxiosResponse } from '@elendi/feature-superjson-api'
import { UserSurveyModulesJoined } from '@elendi/feature-survey-modules/shared'

export const useUserSurveyModules = () => {
  const { impersonate } = useImpersonate()

  const getUserSurveyModules = (url: string, impersonate: string | undefined) =>
    readAxiosResponse<{ data: UserSurveyModulesJoined | null }>(
      axios.get<APIResult>(url, { params: { impersonate } }),
    )

  const { data, error, isLoading, mutate } = useSWR(
    [API.surveyModules.getSelf, impersonate],
    ([url, impersonate]) => getUserSurveyModules(url, impersonate),
  )

  return {
    userSurveyModules: data?.data,
    isLoading,
    isError: !!error,
    mutate,
  }
}
