import { datadogLogs } from '@datadog/browser-logs'
import classNames from 'classnames'
import { useSession } from 'next-auth/react'
import { ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import {
  LAYOUT_IS_MOBILE,
  PORTAL_SUB_NAVIGATION,
  PORTAL_SUB_NAVIGATION_MOBILE,
} from '@elendi/util-constants'
import { useIsMobile } from '@elendi/util-hooks'
import { PageType } from '@elendi/util-types'

import { ErrorMessage } from '@elendi/ui-loaders'

import { CrumbBase } from './Breadcrumbs'
import { AdminIndicators } from './navbar/navigation/AdminIndicators'
import { ManagerViewSwitch } from './navbar/navigation/ManagerViewSwitch'
import { Navigation } from './navbar/navigation/Navigation'
import { SpecificImpersonateViewSwitch } from './navbar/navigation/SpecificImpersonateViewSwitch'
import { ElendiLogo, TopSideBrand } from './navbar/navigation/TopSideBrand'
import { LanguageChanger } from './navbar/user-menu/LanguageChanger'
import { ThemeChanger } from './navbar/user-menu/ThemeChanger'
import { UserMenu } from './navbar/user-menu/UserMenu'

export const LayoutWrapper = ({
  children,
  pageType,
  breadcrumbs,
  hideContent,
}: {
  children: ReactNode
  pageType: PageType
  breadcrumbs: Array<CrumbBase>
  hideContent?: boolean
}) => {
  const { data: session } = useSession()
  const isMobile = useIsMobile(LAYOUT_IS_MOBILE)

  const showContent = !hideContent && !!session

  if (isMobile)
    return (
      <>
        <header className='sticky top-0 left-0 w-full z-40 min-h-16 flex items-center bg-white/75 dark:bg-slate-900/75 backdrop-blur-lg border-b border-slate-300 dark:border-slate-900'>
          {showContent && <AdminIndicators />}

          <div className='pl-4'>{showContent && <UserMenu pageType={pageType} />}</div>

          <div className='flex-1'>
            <div className='px-4 w-32'>
              {showContent ? <TopSideBrand pageType={pageType} /> : <ElendiLogo />}
            </div>
          </div>

          <div className='flex gap-4 p-4'>
            {showContent ? (
              <ManagerViewSwitch pageType={pageType} />
            ) : (
              <div className='flex flex-row items-center gap-4'>
                <LanguageChanger />
              </div>
            )}
          </div>
        </header>

        <MainContent>{children}</MainContent>

        {showContent && (
          <footer className='fixed bottom-0 left-0 w-full z-40'>
            <div id={PORTAL_SUB_NAVIGATION_MOBILE} />

            <div className='h-16 flex items-center justify-center bg-white border-t border-slate-300 dark:border-slate-900 dark:bg-slate-900'>
              <Navigation pageType={pageType} breadcrumbs={breadcrumbs} />
            </div>
          </footer>
        )}
      </>
    )

  return (
    <>
      <header className='sticky top-0 left-0 w-full z-40'>
        <div className='min-h-16 grid grid-cols-desktop-header justify-items-center items-center bg-white/75 backdrop-blur-lg border-b border-slate-300 dark:border-slate-900 dark:bg-slate-900/75'>
          <div className='px-4 w-32 justify-self-start'>
            {showContent ? <TopSideBrand pageType={pageType} /> : <ElendiLogo />}
          </div>

          {showContent && <Navigation pageType={pageType} breadcrumbs={breadcrumbs} />}

          {showContent && <AdminIndicators />}

          <div className='flex gap-4 px-4 justify-self-end'>
            {showContent ? (
              <>
                <SpecificImpersonateViewSwitch pageType={pageType} />
                <ManagerViewSwitch pageType={pageType} />
                <UserMenu pageType={pageType} />
              </>
            ) : (
              <div className='flex flex-row items-center gap-4'>
                <LanguageChanger />

                <ThemeChanger />
              </div>
            )}
          </div>
        </div>

        <div id={PORTAL_SUB_NAVIGATION} />
      </header>

      <MainContent>{children}</MainContent>
    </>
  )
}

const MainContent = ({ children, hideContent }: { children: ReactNode; hideContent?: boolean }) => {
  return (
    <main
      className={classNames(
        'w-full flex flex-col md:gap-12 max-w-7xl mx-auto py-0 md:py-10 px-0 sm:px-8 md:px-16 lg:px-24 2xl:px-0',
        hideContent ? 'px-0 py-0' : 'px-2 py-4',
      )}
    >
      <ErrorBoundary
        fallback={<ErrorMessage />}
        onError={(error) => datadogLogs.logger.error('App client crash', undefined, error)}
      >
        {children}
      </ErrorBoundary>
    </main>
  )
}
