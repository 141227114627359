import Link from 'next/link'
import { useRouter } from 'next/router'
import { RiArrowRightSLine } from 'react-icons/ri'

import { maintainImpersonate } from '@elendi/feature-authentication/utils'

export type CrumbBase = {
  href?: string
  text: string
  inactiveNav?: boolean
}

type CrumbData = CrumbBase & { last: boolean }

export const Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: Array<CrumbBase> }) => (
  <div className='flex space-x-1'>
    {breadcrumbs.map((crumb, idx) => (
      <Crumb {...crumb} key={idx} last={idx === breadcrumbs.length - 1} />
    ))}
  </div>
)

const Crumb = ({ text, href, last = false }: CrumbData) => {
  const { query } = useRouter()

  if (last || !href) return <p>{text}</p>

  return (
    <div className='flex items-center space-x-1 font-semibold'>
      <Link href={{ pathname: href, query: maintainImpersonate({ query }) }} className='text-link'>
        {text}
      </Link>

      <RiArrowRightSLine />
    </div>
  )
}
