import { Session } from 'next-auth'

import { Role } from './authentification'
import { getRole } from './get-session-informations'

export const isAdmin = (session: Session | null) => getRole(session) === Role.ADMIN
export const isManager = (session: Session | null) => getRole(session) === Role.MANAGER
export const isSuperManager = (session: Session | null) => getRole(session) === Role.SUPER_MANAGER
export const isHr = (session: Session | null) => getRole(session) === Role.HR
export const isPeer = (session: Session | null) => getRole(session) === Role.PEER
export const isUnverified = (session: Session | null) => getRole(session) === Role.UNVERIFIED
export const isUser = (session: Session | null) => getRole(session) === Role.USER

export const hasEmail = (session: Session | null) => !!session?.user?.email

export const isAtLeastSuperManager = (role: Role) =>
  [Role.SUPER_MANAGER, Role.HR, Role.ADMIN].includes(role)
export const isAtLeastManager = (role: Role) =>
  [Role.MANAGER, Role.SUPER_MANAGER, Role.HR, Role.ADMIN].includes(role)
export const isAtLeastUser = (role: Role) =>
  [Role.MANAGER, Role.SUPER_MANAGER, Role.HR, Role.ADMIN, Role.USER].includes(role)

export const isLoggedIn = (session: Session | null) => !isLoggedOut(session)
export const isLoggedOut = (session: Session | null) =>
  getRole(session) === Role.UNVERIFIED || getRole(session) === Role.LOGGED_OUT
